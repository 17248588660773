<template>
  <section class="m-login m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img src="@/assets/login/logo2@2x.png" alt="img" class="logo">
      <p class="tips">Verifications</p>
    </div>

    <div class="main code">
        <p v-if="$route.query.phone">A 6-digits pin has been sent to your mobile, enter it below to continue</p>
        <p v-else>A 6-digits pin has been sent to your email address, enter it below to continue</p>
        <p>Code verification</p>
        <div class="input-box" v-if="isCode">
            <input ref="input" type="number" v-model="code" @focus="focusHandle" @blur="blurHandle">
            <div class="input-view">{{code}}</div>
            <div class="input-line">
              <span v-for="i in 6" :key="i" :class="{'input-active': (code.length >= i) && isFocus}"></span>
            </div>
        </div>
        <van-button type="default" v-else @click="sendPhone($route.query.phone)">Again</van-button>
    </div>
  </section>
</template>

<script>
import { phone_code, phone_login, email_code, email_login } from '@/api/zh/login'
import { getCoupon } from "@/api/en/night_market.js";
import { getAccountInfo } from "@/api/zh/mine";

export default {
  name:'Code',
  data() {
    return {
      code: '',
      isFocus: false,
      isCode: true
    }
  },

  created(){
    this.$store.commit('load')
    if(this.$route.query.phone) {
      this.sendPhone(this.$route.query.phone)
    }
    if(this.$route.query.email) {
      this.sendEmail(this.$route.query.email)
    }
  },

  methods:{
        focusHandle() {
            // 输入框聚焦
            this.isFocus = true
        },
        blurHandle() {
            // 输入框聚焦
            this.isFocus = false
        },

        // 发送手机验证码
        sendPhone(phone) {
          let params = { 
              phone    : phone,
              area_code: phone.length == 11 ? 86: 1,
              model    : 'login'
          }
          this.$store.commit('load')
          this.isCode = true
          phone_code(params).then(res => {
            if(res) {
              this.$store.commit('unload')
              this.$refs.input.focus()
            } else {
              this.isCode = false
              this.$store.commit('unload')
            }
          })
        },

        // 验证手机验证码
        checkPhone(code) {
          let params = { 
              phone    : this.$route.query.phone,
              area_code: this.$route.query.phone.length == 11 ? 86: 1,
              code     : code,
              night_token: '',
              is_coupon: window.localStorage.getItem("coupon") ? 1 : ''
           }
           if (localStorage.getItem('token2')) {
            params.night_token = localStorage.getItem('token2')
          }
          phone_login(params).then(res => {
            if(res) {
              localStorage.setItem('token', res.access_token)
              localStorage.setItem('token2', res.access_token)
              if (res.is_night && res.is_night == 1) {
                getCoupon();
              }
              getAccountInfo().then(res => {  
                if(res){
                    localStorage.setItem('setPersonal',JSON.stringify(res.data)) 
                    if(this.$route.query.cart == 1) {
                      this.$router.push('/en/cart?cart=1')
                    } else if(this.$route.query.bargain == 1) {
                      this.$router.push('/en/bargain')
                    } else if(localStorage.getItem("pay_titleF")){
                       this.$router.push('/en')
                    } else {
                      this.$router.go(-2)
                    }
                }
              })
            }
          })
        },

        // 发送邮箱验证码
        sendEmail(email) {
          let params = { 
              email: email,
              model: 'login'
          }
          email_code(params).then(res => {
            if(res) {
              this.$store.commit('unload')
              this.$refs.input.focus()
            }
          })
        },

        // 验证手机验证码
        checkEmail(code) {
          let params = { 
              email: this.$route.query.email,
              code : code,
              night_token: '',
              is_coupon: window.localStorage.getItem("coupon") ? 1 : ''
           }
            if (localStorage.getItem('token2')) {
            params.night_token = localStorage.getItem('token2')
          }
          email_login(params).then(res => {
            if(res) {
              localStorage.setItem('token', res.access_token)
              localStorage.setItem('token2', res.access_token)
              if (res.is_night && res.is_night == 1) {
                getCoupon();
              }
              getAccountInfo().then(res => {  
                if(res){
                    localStorage.setItem('setPersonal',JSON.stringify(res.data)) 
                    if(this.$route.query.cart == 1) {
                      this.$router.push('/en/cart?cart=1')
                    } else if(localStorage.getItem("pay_titleF")){
                      this.$router.push('/en')
                    } else {
                      this.$router.go(-2)
                    }
                }
              })
            }
          })
        },
  },
  watch: {
      code(val, old) {
          if(val.length === 6) {
            if(this.$route.query.phone) {
              this.checkPhone(val)
            }
            if(this.$route.query.email) {
              this.checkEmail(val)
             }
          }
          if(val.length > 6) {
              this.code = old
          }
      }
  }
}
</script>

<style lang="less" scoped>
@import './login.less';
</style>